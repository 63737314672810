.calendar-events{
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 80px auto;

    &:hover{
        cursor: pointer;
    }

    @media (max-width: 768px) {
        justify-content: center;
    }

    @media (min-width: 769px) and (max-width: 1024px)  {
        justify-content: space-between
    }

    .calendar-events_title{
        font-weight: 700;
        font-size: 35px;
        display: flex;
        justify-content: center;
        letter-spacing: 6px;
        margin-bottom: 60px;
        padding: 20px;
        width: 100%;
    }

    .calendar-events-noresults{
        font-size: 25px;
        font-weight: 500;
        letter-spacing: 0.05em;
        line-height: 1.5;
        margin: 80px auto 0;
        text-align: center;
        text-transform: uppercase;
        width: 50%;

        a{
            color: var(--dark-violet);
            text-decoration: underline;
        }
    }

    .calendar-events_cards{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .calendar-events_viewed{
        filter: drop-shadow(0px 4px 3px var(--shadow-color));
        margin: 0 25px;
        width: 35%;

        @media (max-width: 769px) {
            display: none;
        }

        @media (max-width: 1025px) {
            margin-top: 30px;
        }

        .calendar-events_viewed_img img{
            border-radius: 15px 15px 0px 0px;
            display: flex;
            height: 100%;
            max-height: 200px;
            object-fit: cover;
            width: 100%;
        }

        .calendar-events_viewed_content{
            font-weight: 500;
            line-height: 125%;
            background-color: var(--light-gray);
            border-radius: 0px 0px 15px 15px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;                     
        
            h3{
                font-size: 22px;
                letter-spacing: 1px;
                line-height: 1.2;
                margin-bottom: 15px;
            }
    
            p{
                position: relative;
                padding-left: 30px;
                margin-top: 15px;
                letter-spacing: 1px;
            }    
        }
    }

    
}

.icon::before{
    background-repeat: no-repeat;
    background-position: center center;
    content: "";
    height: 18px;
    width: 18px;
    position: absolute;
    left: 0;

    @media (max-width: 520px) {
        height: 15px;
        width: 15px;
    }
}

.icon-bell::before{
    background-image: url('../../../svg/icon-bell.svg');
}

.icon-clock::before{
    background-image: url('../../../svg/icon-clock.svg');
}

.icon-smile::before{
    background-image: url('../../../svg/icon-smile.svg');
}
