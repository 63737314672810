.project_modal{
    align-items: center;
    display: none;
    background: url('../../../images/bg.png'), #ffffffe7;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    justify-content: center;    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    will-change: transform;
    width: 100%;
    z-index: 999;
    
    &.show{
        display: flex;
        animation: fadeIn 0.6s linear;
    }

    &.hide{
        display: none;
    }

    @keyframes fadeIn {
        0%{
            opacity: 0;
        }

        100%{
            opacity: 1;
        }
    }
    
    @media (max-width: 1025px) {
        display: none;
    }

    &_container{
        background-color: var(--light-gray);
        border-radius: 25px;
        box-shadow: 0px 5px 32px var(--shadow-color);
        display: flex;
        height: 100%;
        max-width: 910px;
        max-height: 580px;
        justify-items: center;
        justify-content: space-evenly;
        padding: 60px;
        position: relative;
        width: 100%;

        &_btn{
            background-color: transparent;
            border: none;
            height: 20px;
            width: 20px;
            position: absolute;
            top: 2%;
            right: 3%;

            &:hover{
                cursor: pointer;
            }
        }
    
        &_btn::before,
        &_btn::after{
            border-top: 3px solid;
            content: '';
            display: block;
        }
    
        &_btn::before{
            rotate: -45deg;
            translate: 0px 2px;
        }
        &_btn::after{
            rotate: 45deg;
        }

        .project_modal_slider{
            display: grid;
            grid-template-areas:
            "description image"
            "description tags"
            "description upload";
            grid-template-columns: 50% 50%;
            grid-template-rows: 1fr;
            height: 100%;
            justify-items: center;
            justify-content: space-evenly;
            position: relative;
            width: 100%;
    
            .project_modal_content{
                grid-area: description;
                padding: 0 15px;
        
                .project_modal_content_title{
                    font-size: 20px;
                    margin-bottom: 20px;
                    text-transform: uppercase;
                }
            
                .project_modal_content_description{
                    font-family: var(--family-inter);
                    font-size: 14px;
                    line-height: 1.7;
                    width: 90%;
                }
            }        
        
            .project_modal_image{
                border-radius: 15px;
                grid-area: image;
                height: 300px;
                object-fit: cover;
                width: 100%;
            }
        
            .project_modal_tags{
                align-items: baseline;
                display: flex;
                font-size: 11px;
                grid-area: tags;
                justify-content: space-between;
                padding-top: 15px;
                padding-bottom: 20px;
                width: 100%;
            }
        
            .project_files{
                grid-area: upload;
                height: min-content;        
                margin-top: 20px;
                width: 100%;      
        
                &_btn{
                    align-items: flex-end;
                    background-color: var(--light-orange);
                    border: none;
                    border-radius: 5px;
                    box-shadow: 0px 3px 6px var(--shadow-color);
                    color: var(--dark-violet);
                    display: flex;
                    font-weight: 500;
                    font-size: 10px;
                    font-family: var(--family-oswald);
                    line-height: 1.25;
                    letter-spacing: 0.05em;
                    padding: 5px;
                    text-transform: uppercase;
                    width: 100%;
        
                    &:disabled{
                        background-color: var(--gray);
                        opacity: 0.7;
        
                        &:hover{
                            cursor: url('../../../svg/icon-block.svg'), auto;
                        }                       
                    }
                    
                    &:hover{
                        cursor: pointer;
                    }
                }
        
                &_btn::before{
                    background: url('../../../svg/icon-upload.svg');
                    content: '';
                    margin-right: 10px;
                    height: 15px;
                    width: 18px;
                }
            }
        }

        .slick-slider.slick-initialized{
            width: 100%;

            .slick-list {
                margin-left:-27px;
            }

            .slick-prev {
                left: -150px;
    
                @media (max-width: 1025px) {
                    display: none !important;
                }
            }
    
            .slick-next {
                right: -130px;
    
                @media (max-width: 1025px) {
                    display: none !important;
                }
            }
    

            .slick-prev::before{            
                background: url('../../../svg/arrow-left.svg'), var(--light-gray);           
            }
    
            .slick-next::before{            
                background: url('../../../svg/arrow-right.svg'), var(--light-gray);            
            }
    
            .slick-prev::before, .slick-next::before{
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 50%;
                box-shadow: 0px 2px 6px var(--shadow-color);
                content: '';
                display: block;
                font-size: 40px;
                height: 40px;
                width: 40px;
            }
    
            .slick-dots li button:before{
                font-size: 14px;
                color: var(--light-violet);
                padding-top: 20px
            }

            .slick-dots {
                bottom: -90px;
            }
        }
    }    
}